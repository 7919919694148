<script>
import Vue from 'vue'
import UserAlert, { BASE_VARIANT } from '@sigmacloud/sigma-client/dist/user-alerts'
import { readableNameFromResourceClass } from '@sigmacloud/sigma-client/dist/util/resources/name'
import { toCamelCase } from '@sigmacloud/sigma-client/dist/util/string'
import { mapGetters } from '@/store'

export default Vue.extend({
    name: 'BaseDetail',

    props: {
        instanceName: {
            type: String,
            required: true,
        },
        id: {
            type: [Number, String],
            default: null,
        },
        resourceClassName: {
            type: String,
            required: true,
        },
        displayAsModal: Boolean,
        readonly: Boolean,
    },

    data() {
        return {
            loading: true,
            saving: false,
            resource: null,
            defaultOptions: {},
        }
    },

    computed: {
        readableResourceClass: ({ resourceClassName }) => readableNameFromResourceClass(resourceClassName),
        pageAction: ({ resource }) => (resource && !resource.isNew() ? resource : 'Create'),
        pageTitle: ({ pageAction, readableResourceClass }) => `${readableResourceClass} | ${pageAction}`,
        camelCaseResource: ({ readableResourceClass }) => toCamelCase(readableResourceClass),
        formsToValidate: ({ $refs }) => [$refs.main],
        goToList: ({ camelCaseResource, instanceName }) => {
            return { name: `${camelCaseResource}List`, params: { instanceName } }
        },
        goToDetail: ({ camelCaseResource, instanceName, id }) => {
            return { name: `${camelCaseResource}Detail`, params: { instanceName, id } }
        },
        ...mapGetters(['userResource', 'instanceURL', 'instanceName']),
    },

    watch: {
        id() {
            this.loadData({ useCache: false })
        },
    },

    async beforeMount() {
        try {
            await this.loadData()
        } catch (e) {
            if (e.response && e.response.status === 404) {
                this.$router.push({ name: 'notFound' })
            }
        }
    },

    mounted() {
        return document.addEventListener('keydown', this.handleKeyPress)
    },

    destroyed() {
        return document.removeEventListener('keydown', this.handleKeyPress)
    },

    methods: {
        createUserAlert(error) {
            if (error.isAxiosError !== undefined) return UserAlert.fromAxiosError(error)
            return new UserAlert(error.message, { variant: BASE_VARIANT.DANGER })
        },
        promisesToResolveBeforeSave() {
            return []
        },
        promisesToResolveAfterSave() {
            return []
        },
        validateForms() {
            return this.formsToValidate.reduce((totalErrors, currentForm) => {
                return [...totalErrors, ...currentForm.validate()]
            }, [])
        },
        async saveAndReturnToList() {
            try {
                let response = await this.saveResource(
                    {
                        resource: this.readableResourceClass,
                        path: this.goToDetail,
                    },
                    0
                )
                if (response) this.$router.push(this.goToList)
            } catch (e) {
                this.$store.commit('setResourceName', this.resource.toString())
                this.$store.commit('pushUserAlert', this.createUserAlert(e))
            }
        },
        async saveAndAddAnother() {
            try {
                await this.saveResource(
                    {
                        resource: this.readableResourceClass,
                        path: this.goToDetail,
                    },
                    0
                )
                this.loading = true
                this.resource = undefined
                this.$router.push({ name: `${this.camelCaseResource}Create`, params: { instanceName: this.instanceName } })
            } catch (e) {
                this.$store.commit('setResourceName', this.resource.toString())
                this.$store.commit('pushUserAlert', this.createUserAlert(e))
            } finally {
                this.loading = false
            }
        },
        async saveAndContinue() {
            try {
                let isNewResource = this.resource.isNew()
                let resourceId = await this.saveResource()
                if (resourceId && isNewResource) {
                    this.$router.push({ name: `${this.camelCaseResource}Detail`, params: { instanceName: this.instanceName, id: resourceId } })
                }
            } catch (e) {
                this.$store.commit('setResourceName', this.resource.toString())
                this.$store.commit('pushUserAlert', this.createUserAlert(e))
            }
        },
        async saveResource(link = false, timeout = 3) {
            if (!this.resource.isNew() && !Object.keys(this.resource.changes).length) {
                this.isFormChanged = true
                return ''
            }

            let errors = this.validateForms()
            let loggedUserId = ''
            // Check userResource is exist or not
            if (this.userResource) loggedUserId = this.userResource.attributes.id
            let resourceResult

            if (errors.length > 0) {
                throw new Error('Unable to save due to validation errors')
            }

            try {
                this.saving = true

                await Promise.all(this.promisesToResolveBeforeSave())
                // Check attributes key is exist or not
                if (Object.keys(this.resource.attributes).length) {
                    // Add 'from_v2' to the payload
                    this.resource.attributes['from_v2'] = true
                }
                resourceResult = await this.resource.save()
                // Check route name is 'purchaseOrderCreate' or not
                if (this.$route.name === 'purchaseOrderCreate') {
                    // Hit the api( PATCH call)
                    let response = await this.resource
                    response.set('from_v2', `${true}`)
                    response.set('created_by', `${loggedUserId}`)
                    response.save()
                }
                await Promise.all(this.promisesToResolveAfterSave())
                this.$store.commit('setResourceName', this.resource.toString())
                this.$store.commit(
                    'pushUserAlert',
                    new UserAlert(`${this.readableResourceClass} saved successfully`, {
                        variant: BASE_VARIANT.SUCCESS,
                        timeout,
                        link,
                    })
                )
            } finally {
                this.saving = false
            }

            return resourceResult ? resourceResult.resources[0].id : false
        },
        async loadData(options = {}) {
            this.loading = true
            if (this.id) {
                await this.getResource(options)
            } else {
                await this.newResource(this.defaultOptions)
            }
            this.loading = false
        },
        async getResource(options = {}) {
            this.resource = await this.$resources[this.resourceClassName].detail(this.id, options)
        },
        async newResource(options = {}) {
            this.resource = new this.$resources[this.resourceClassName](options)
        },
        handleKeyPress(e) {
            const modifier = navigator.appVersion.includes('Mac') ? e.metaKey : e.ctrlKey

            if (modifier === false) return

            switch (e.key) {
                case 's':
                    e.preventDefault()
                    return this.saveAndContinue()
            }
        },
        openResource(name, id) {
            this.$router.push({ name, params: { instanceName: this.instanceName, id } })
        },
    },
})
</script>

<template>
    <div v-if="!loading" v-page-title="pageTitle">
        <page-header v-if="!displayAsModal" :title="pageTitle">
            <template #title>
                <router-link :to="goToList">{{ readableResourceClass }}</router-link> | {{ pageAction }}
            </template>
            <template #actions-right>
                <b-dropdown :disabled="saving" split boundary="viewport" right class="ml-2 user-select-none" @click="saveAndContinue">
                    <template #button-content>Save<b-spinner v-if="saving" small /></template>
                    <b-dropdown-item @click="saveAndAddAnother">Save and Add Another</b-dropdown-item>
                    <b-dropdown-item @click="saveAndReturnToList">Save and Return to List</b-dropdown-item>
                </b-dropdown>
            </template>
        </page-header>
        <b-container fluid>
            <b-row>
                <b-col>
                    <resource-card title="Basic Information" class="my-3">
                        <resource-edit-form
                            ref="main"
                            :resource="resource"
                            :formset="resource.getFormset()"
                            :focus="Object.keys(resource.getFormset())[0]"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
