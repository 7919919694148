<script>
import BaseDetail from '../BaseDetail'
import { ValidationError } from 'rest-resource/dist/exceptions'
import { normalizeUserDateInput } from '@sigmacloud/sigma-client/dist/util/date'
import FileList from '@sigmacloud/sigma-client/dist/components/FileList'
import { ReportParamResource, ReportPoTransaction, PurchaseOrderResource } from '@sigmacloud/sigma-client/dist/resources'

export default {
    name: 'PurchaseOrderDetail',
    components: { FileList },
    extends: BaseDetail,

    data() {
        return {
            vendorResource: null,
            vendorAddressResource: null,
            pdfData: null,
            renderPageheader: 0,
            printPoBtn: 'Print PO',
            defaultLineData: {},
            rejectPoComment: '',
            buttonLabel: 'Save',
            isFormChanged: false,
        }
    },
    computed: {
        formsToValidate() {
            return [this.$refs.poDetails, this.$refs.vendorDetails, this.$refs.linesDetails]
        },
        selectedVendor() {
            return this.resource ? this.resource.attributes.vendor : null
        },
        readonly() {
            // Check resource data is exsit or not
            if (this.resource) {
                // return 'true', if the status is POSTED
                return this.resource.attributes && this.resource.attributes.status == 'POSTED'
            }
            return false
        },
    },
    watch: {
        selectedVendor(newVendor, oldVendor) {
            if (newVendor === oldVendor) return
            if (newVendor) {
                this.getVendorData()
            } else {
                this.clearVendorData()
            }
        },
        vendorResource() {
            if (this.resource.isNew()) {
                this.loadDefaultAccounts()
            }
        },
    },
    methods: {
        normalizeUserDateInput,
        promisesToResolveAfterSave() {
            return [this.$refs.linesDetails.save()]
        },
        async loadData() {
            this.loading = true
            await this.setDefaultLineData()
            if (this.id) {
                await this.getResource()
                await this.getVendorData()
            } else {
                let batchData = await this.$resources.BatchResource.list({ query: { ordering: 'id' } })
                await this.newResource({
                    approval_level_required: '3',
                    batch: batchData.resources.length > 0 ? batchData.resources[0].id : null,
                })
                this.clearVendorData()
            }
            this.loading = false
        },
        async getVendorData() {
            this.vendorResource = await this.$resources.VendorResource.detail(this.resource.attributes.vendor)
            if (this.vendorResource.attributes.address_selected) {
                this.vendorAddressResource = await this.$resources.AddressResource.detail(this.vendorResource.attributes.address_selected.id, {
                    resolveRelatedDeep: true,
                })
            }
        },
        clearVendorData() {
            this.vendorResource = null
            this.vendorAddressResource = null
        },
        async approve() {
            await this.resource.approve()
            await this.resource.update()
            this.resource.changes = {}
        },
        async validateAndSave() {
            let errors = [...this.validateForms(), ...this.validateBusinessLogic()]
            if (errors.length < 1) {
                await this.saveAndReturnToList()
            }
        },
        validateBusinessLogic() {
            let poOpenOrDraft = ['DRAFT', 'OPEN'].includes(this.resource.attributes.status)
            let poTotalAmountEqualTosumOfLines = this.$refs.linesDetails.sumLines === (this.resource.attributes.amount_decimal || '0.00')
            let errors = []

            if (poOpenOrDraft && !poTotalAmountEqualTosumOfLines) {
                let error = {
                    title: 'Form Error',
                    description: 'Line Amounts do not add up to PO amount. Please check the PO amount',
                }
                errors.push(new ValidationError(error.title, error.description))
                // This is a temporary solution. @todo: design and implement a real toast system.
                this.$bvToast.toast(error.description, {
                    title: error.title,
                    variant: 'danger',
                    solid: true,
                })
            }
            return errors
        },
        async setDefaultLineData() {
            let seriesData = await this.$resources.SeriesResource.list({ query: { ordering: 'id' } })
            let locationData = await this.$resources.LocationResource.list({ query: { ordering: 'id' } })
            let setData = await this.$resources.SetResource.list({ query: { ordering: 'id' } })
            this.defaultLineData = {
                series: seriesData.resources[0].id,
                location: locationData.resources[0].id,
                set: setData.resources[0].id,
            }
        },
        async loadDefaultAccounts() {
            if (!this.vendorResource) return
            let defaultAccounts = this.vendorResource.attributes.default_accounts
            this.$refs.linesDetails.resources.forEach((line, index) => {
                if (index < defaultAccounts.length && !line.get('account')) line.set('account', defaultAccounts[index])
            })
        },
        formatChangeMessages(change_message) {
            let messageJSON = change_message.match(/(\{.*\})/g)
            let result = []
            if (messageJSON) {
                let messageObject = JSON.parse(messageJSON)
                let changeKeys = Object.keys(messageObject.current)
                changeKeys.forEach((changeKey) => {
                    let previous = messageObject.previous[changeKey]
                    let current = messageObject.current[changeKey]
                    result.push('Changed ' + changeKey + ' from "' + previous + '" to "' + current + '"')
                })
            } else {
                result.push(change_message)
            }
            return result
        },
        statusIcon(status) {
            if (status === 'POSTED') return 'check-circle-fill'
            if (status === 'CLOSED') return 'slash-circle-fill'
            return 'info-circle-fill'
        },
        isApprovable(resource) {
            if (!resource) return false
            if (['POSTED', 'CLOSED', 'REJECTED'].includes(resource.attributes.status)) {
                return false
            }
            return true
        },
        // Open Reject PO modal to save the comment info
        openRejectPO() {
            this.rejectPoComment = ''
            this.buttonLabel = 'Save'
            // Open Reject PO modal
            this.$bvModal.show('resource-reject-po')
        },
        // Save Reject PO comment info
        async saveRejectComment() {
            this.buttonLabel = 'Saving...'
            try {
                // Get endpoint i.e., /pos/:id , where ':id' means this.id
                let getEndPoint = await PurchaseOrderResource.detail(this.id)
                // Append '/reject' to the endpoint URL
                let appendEndpoint = getEndPoint.wrap('/reject')
                // Hit the API to save the comment info
                let resp = await appendEndpoint.post({ comment: this.rejectPoComment })
                // Check response is exist or not
                if (resp?.status) {
                    this.buttonLabel = 'Saved'
                    // Close Reject PO modal
                    this.$bvModal.hide('resource-reject-po')
                    // Update the resource data
                    await this.resource.update()
                    this.resource.changes = {}
                } else this.buttonLabel = 'Save'
            } catch (e) {
                this.buttonLabel = 'Save'
                console.log(e)
            }
        },
        // To print PO Details in a file (eg: .pdf)
        async printPODetails() {
            // Change the button name
            this.printPoBtn = 'Printing...'
            // Re-render the page header
            this.renderPageheader += 1
            // To call the API with required payload
            let poAPI = new ReportParamResource({
                data: {
                    purchaseorder: this.id,
                },
            })
            // Get PO respose data
            let poResponse = await poAPI.save()
            // Check response is exist or not
            if (poResponse?.response) {
                // Get the generated 'ID'
                let generatedId = poResponse.response.data.id
                // Check 'generatedId' is exist or not
                if (!generatedId) return
                /**
                 * Query: "?cached_parameters=783&report_template=default"
                 * To achieve the above 'Query' we need to pass payload as below format
                 * ReportPoTransaction.filter({ cached_parameters: generatedId, report_template: 'default' })
                 */
                let poTransactionResponse = await ReportPoTransaction.filter({ cached_parameters: generatedId, report_template: 'default' })
                if (poTransactionResponse?.body) {
                    // Assign blob data to open as pdf
                    this.pdfData = poTransactionResponse.body.data
                    // Open modal to show the PO details in pdf format
                    this.$bvModal.show('print-po-modal')
                }
            }
            // Reset the button name
            this.printPoBtn = 'Print PO'
            // Re-render the page header
            this.renderPageheader += 1
        },
    },
}
</script>

<template>
    <div v-if="!loading">
        <b-alert v-model="isFormChanged" variant="warning" show dismissible>There are no changes to update at this time to Save.</b-alert>
        <page-header v-if="!displayAsModal" :key="renderPageheader" v-page-title="pageTitle" :title="pageTitle">
            <template #title>
                <router-link :to="goToList">{{ readableResourceClass }}</router-link> | {{ pageAction }}
            </template>
            <template #actions-right>
                <b-button v-if="!resource.isNew()" :disabled="!isApprovable(resource)" @click="openRejectPO()">Reject PO</b-button>
                <b-button v-if="!resource.isNew()" :disabled="printPoBtn == 'Print PO' ? false : true" class="ml-2" @click="printPODetails()">{{
                    printPoBtn
                }}</b-button>
                <b-button
                    v-if="!resource.isNew()"
                    v-user-scope-or.hide="['po_approvals', 'po_approvals_level_2', 'po_approvals_level_3']"
                    :disabled="!isApprovable(resource)"
                    class="ml-2"
                    @click="approve">
                    Approve
                </b-button>
                <b-button v-user-scope-or.hide="['dataentry']" class="ml-2" :disabled="saving || readonly" @click="validateAndSave">
                    Save <b-spinner v-if="saving" small />
                </b-button>
            </template>
        </page-header>

        <b-row>
            <b-col md="6">
                <resource-card title="Vendor Details" class="my-3">
                    <resource-edit-form
                        ref="vendorDetails"
                        :resource="resource"
                        :formset="resource.getFormset(['vendor'])"
                        focus="vendor"
                        :readonly="readonly"
                        no-submit
                        no-icons
                        @openResource="openResource" />
                    <div v-if="vendorResource && vendorAddressResource">
                        <p>
                            <label>Address</label>
                            <template v-if="vendorResource.attributes.address_selected">
                                <strong>
                                    <br />
                                    <resource-format :resource="vendorAddressResource" display-template="line1" />
                                </strong>
                                <strong v-if="vendorResource.attributes.address_selected.line2">
                                    <br />
                                    <resource-format :resource="vendorAddressResource" display-template="line2" />
                                </strong>
                                <strong>
                                    <br />
                                    <resource-format :resource="vendorAddressResource" display-template="${city}, ${state.code} ${postal}" />
                                </strong>
                            </template>
                            <template v-else>
                                <strong><br />No Address on file</strong>
                            </template>
                        </p>
                        <p>
                            <label>Tax ID</label>
                            <strong>
                                <br />
                                <resource-format display-template="tax_id" :resource="vendorResource">
                                    <template #default="data">
                                        <span v-if="!data.loading">
                                            {{ data.value == 'null' ? 'No Tax ID on file' : data.value }}
                                        </span>
                                        <span v-else>Loading...</span>
                                    </template>
                                </resource-format>
                            </strong>
                        </p>
                        <p>
                            <label>Status</label>
                            <strong>
                                <br />
                                <resource-format display-template="active" :resource="vendorResource">
                                    <template #default="data">
                                        <span v-if="!data.loading">
                                            {{ data.value ? 'Active' : 'Inactive' }}
                                        </span>
                                        <span v-else>Loading...</span>
                                    </template>
                                </resource-format>
                            </strong>
                        </p>
                    </div>
                </resource-card>
            </b-col>
            <b-col md="6">
                <resource-card title="PO Details" class="my-3">
                    <resource-edit-form
                        ref="poDetails"
                        :resource="resource"
                        :formset="resource.getFormset(['id', 'number', 'amount_decimal', 'description', 'batch', 'date', 'status'])"
                        :readonly="readonly"
                        no-submit
                        no-icons>
                        <template #field-control-status="{ field, resource }">
                            <b-input-group>
                                <component
                                    v-bind="field.props"
                                    :is="field.component"
                                    :ref="`field-status`"
                                    :value="resource.prettyStatus"
                                    :field="field"
                                    keyname="status"
                                    :resource="resource"
                                    readonly />
                                <template #append>
                                    <b-input-group-text id="status-tooltip-icon" v-b-modal.resource-next-approvers>
                                        <b-icon
                                            :icon="statusIcon(resource.attributes.status)"
                                            :variant="resource.attributes.status == 'POSTED' ? 'success' : ''" />
                                    </b-input-group-text>
                                </template>
                            </b-input-group>
                            <b-tooltip v-if="!['POSTED', 'CLOSED'].includes(resource.attributes.status)" target="status-tooltip-icon" triggers="hover">
                                <ul class="status-tooltip-text">
                                    <strong>Approvals Needed:</strong>
                                    <li v-for="n in resource.approvalLevelsRemaining" :key="n">Level {{ n }}</li>
                                </ul>
                            </b-tooltip>
                        </template>
                    </resource-edit-form>
                </resource-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <resource-card title="PO Lines" class="my-3">
                    <resource-dist-lines-simple
                        ref="linesDetails"
                        :resource="resource"
                        :default-line-data="defaultLineData"
                        rows="10"
                        ordering="line_number"
                        :readonly="readonly"
                        :enable-actions="!readonly" />
                </resource-card>
            </b-col>
        </b-row>
        <b-row v-if="!resource.isNew()">
            <b-col md="12">
                <resource-card title="PO History" class="my-3">
                    <resource-history :resource="resource" :resolve-attributes="['user.username']">
                        <template #value-user="{ resource }">
                            <user-avatar :user-resource="resource.get('user')" size="1.75em" />
                        </template>
                        <template #value-action_time="{ resource }">
                            {{ resource.get('action_time') }}
                        </template>
                        <template #value-change_message="{ resource }">
                            <div>
                                <span v-for="(changeMessage, index) in formatChangeMessages(resource.get('change_message'))" :key="index">
                                    {{ changeMessage }}<br />
                                </span>
                            </div>
                        </template>
                    </resource-history>
                </resource-card>
            </b-col>
        </b-row>

        <b-row v-if="!resource.isNew()">
            <b-col>
                <file-list :resource="resource" :is-disabled="readonly" @error="createUserAlert"></file-list>
            </b-col>
        </b-row>

        <b-modal id="resource-next-approvers" ref="resource-next-approvers" size="lg" title="Approvals Needed" role="dialog" hide-header cancel-only scrollable>
            <template #modal-footer="{ close }">
                <b-button size="sm" @click="close()"> Close </b-button>
            </template>
            <resource-card title="Approvals Needed" class="my-3">
                <resource-next-approvers :resource="resource" display-as-modal />
            </resource-card>
        </b-modal>
        <b-modal id="resource-reject-po" title="Reject PO" hide-footer @click.stop="''">
            <b-row>
                <b-col>
                    <b-form @submit.prevent="saveRejectComment">
                        <b-form-group label="Comment">
                            <b-form-textarea v-model="rejectPoComment" :rows="3" placeholder="Enter your comment"></b-form-textarea>
                        </b-form-group>
                        <button type="submit" class="btn btn-primary float-right" :disabled="!rejectPoComment || buttonLabel == 'Saving...'">
                            {{ buttonLabel }}
                        </button>
                    </b-form>
                </b-col>
            </b-row>
        </b-modal>
        <b-modal id="print-po-modal" size="xl" title="PO Details" hide-footer>
            <object :data="`data:application/pdf;base64,${pdfData}`" type="application/pdf" width="1105" height="600"></object>
        </b-modal>
    </div>
</template>
